export const useShowOnViewPort = (
  element: Ref<HTMLDivElement | null>,
  options?: IntersectionObserverInit,
) => {
  const ratio = ref(0);

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => (ratio.value = entry.intersectionRatio));
  }, options);

  onMounted(() => {
    if (!element.value) {
      return;
    }
    observer.observe(element.value);
  });

  return ratio;
};
