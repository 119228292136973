<script setup lang="ts">
import type { Price } from "@/lib/content";
import type { PostHog } from "posthog-js";
import { PH_EVENTS } from "@/lib/const";
import IconCorrect from "~/assets/icons/correct.svg";
import UIBadge from "@repo/ui/src/components/ui/badge/Badge.vue"
import UIButton from "@repo/ui/src/components/ui/button/Button.vue"
import UISeparator from "@repo/ui/src/components/ui/separator/Separator.vue"

interface Props {
  price: Price;
  type?: "default" | "primary";
}
type LicenseType = 'developer'|'organization'
const props = defineProps<Props>();
const preparingStripeUrl = ref(false)
const { $posthog } = useNuxtApp();
const type = computed(() => props.type ?? "default");
const price = computed(() => props.price);
const backgroundStyle = computed(() => {
  if (type.value === "primary") {
    return `background: rgb(60,56,113);background: linear-gradient(180deg, rgba(60,56,113,1) 0%, rgba(34,36,49,1) 100%);`;
  }
});

const trackEvent = (type: LicenseType) => {
  if (!$posthog) {
    return;
  }
  const posthog = $posthog() as PostHog;
  if (!posthog) {
    return;
  }
  const eventName = type === 'developer' ? PH_EVENTS.purchase.dev : PH_EVENTS.purchase.org
  posthog.capture(eventName);
  posthog.identify(
    posthog.get_distinct_id(),
    { Purchase: 1 }, // optional: set additional user properties
  );
}
// TODO ~ Add the logic in a function
const onPurchase = async () => {
  if (preparingStripeUrl.value) {
    return
  }
  preparingStripeUrl.value = true
  const isOrganizationLicense = type.value === "primary";
  let packageType: LicenseType, couponCode: string;
  if (isOrganizationLicense) {
    packageType = 'organization';
    couponCode = 'EARLYORG40'
  } else {
    packageType = 'developer';
    couponCode = 'EARLYDEV40'
  }
  trackEvent(packageType)
  try {
    const { url } = await $fetch<{url: string}>('/api/payment-service/v1/payment/create-session', {
      params: {
        licenseType: packageType,
        couponCode,
      }
    });
    if (url) {
      window.location.href = `${url}`
    } else {
      console.warn('Internal Server Error, try again later')
    }
  } catch(err) {
    console.log(err)
  } finally {
    preparingStripeUrl.value = false
  }
};
</script>
<template>
  <div
    class="rounded-[20px] cursor-pointer overflow-hidden"
    :class="{
      'border-#E2E6E9 border bg-#F0F2F5 py-10 ': type === 'default',
      'py-12': type === 'primary',
    }"
    :style="backgroundStyle"
    @click="onPurchase"
  >
    <div class="text-center mb-8">
      <UIBadge
        variant="outline"
        class="mx-auto"
        :class="{
          'border-#1C202433': type === 'default',
          'border-#FFFFFF33': type === 'primary',
        }"
      >
        <span
          class="leading-24px"
          :class="{
            'text-white': type === 'primary',
            'text-tag': type === 'default',
          }"
        >
          {{ price.license }}
        </span>
      </UIBadge>
    </div>
    <div
      class="flex items-end mb-8 justify-center"
      :class="{ 'text-white': type === 'primary' }"
    >
      <span
        class="line-through mr-2 font-serif font-normal text-20px leading-none"
        :class="{
          'text-[#C6CCD2]': type === 'primary',
          'text-secondary': type === 'default',
        }"
      >
        {{ price.formerPrize }}
      </span>
      <span
        class="text-brand-light font-bold text-5xl mr-2 font-serif leading-[0.8]"
      >
        {{ price.currentPrize }}
      </span>
      <UIBadge class="bg-#9381FF">
        <span class="text-white text-sm">
          {{ price.totalDiscount }}
        </span>
      </UIBadge>
    </div>
    <div class="mx-5">
      <UIButton
        class="flex w-full"
        :variant="type === 'primary' ? 'default' : 'outline'"
        :disabled="preparingStripeUrl"
      >
        {{ price.textButton }}
      </UIButton>
    </div>
    <UISeparator
      class="my-5"
      :class="{
        'bg-#FFFFFF1A': type === 'primary',
        'bg-#E2E6E9': type === 'default',
      }"
    />
    <div class="px-8">
      <template v-for="(i, idx) in price.licenseFeatures" :key="i">
        <p
          class="flex text-sm items-center"
          :class="{
            'text-white': type === 'primary',
            'mb-2': idx !== price.licenseFeatures.length - 1,
          }"
        >
          <IconCorrect class="mr-3" />{{ i }}
        </p>
      </template>
    </div>
  </div>
</template>
