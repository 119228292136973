<script setup lang="ts"></script>

<template>
  <div class="relative">
    <svg
      width="352"
      height="242"
      viewBox="0 0 352 242"
      class="w-full"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_543_17558)">
        <g clip-path="url(#clip1_543_17558)">
          <g opacity="0.8" filter="url(#filter0_f_543_17558)">
            <ellipse
              cx="140.09"
              cy="80.451"
              rx="61.8388"
              ry="1.38513"
              transform="rotate(-15 140.09 80.451)"
              fill="url(#paint0_radial_543_17558)"
            />
          </g>
          <g opacity="0.8" filter="url(#filter1_f_543_17558)">
            <ellipse
              cx="140.91"
              cy="80.6702"
              rx="61.8388"
              ry="1.38513"
              transform="rotate(15 140.91 80.6702)"
              fill="url(#paint1_radial_543_17558)"
            />
          </g>
          <g opacity="0.8" filter="url(#filter2_f_543_17558)">
            <ellipse
              cx="142.928"
              cy="82.0902"
              rx="61.8388"
              ry="1.38513"
              transform="rotate(75 142.928 82.0902)"
              fill="url(#paint2_radial_543_17558)"
            />
          </g>
          <g opacity="0.8" filter="url(#filter3_f_543_17558)">
            <ellipse
              cx="142.928"
              cy="82.0902"
              rx="61.8388"
              ry="1.38513"
              transform="rotate(75 142.928 82.0902)"
              fill="url(#paint3_radial_543_17558)"
            />
          </g>
          <g opacity="0.8" filter="url(#filter4_f_543_17558)">
            <ellipse
              cx="142.548"
              cy="83.509"
              rx="61.8388"
              ry="1.38513"
              transform="rotate(105 142.548 83.509)"
              fill="url(#paint4_radial_543_17558)"
            />
          </g>
          <g opacity="0.8" filter="url(#filter5_f_543_17558)">
            <ellipse
              cx="142.548"
              cy="83.509"
              rx="61.8388"
              ry="1.38513"
              transform="rotate(105 142.548 83.509)"
              fill="url(#paint5_radial_543_17558)"
            />
          </g>
          <g opacity="0.8" filter="url(#filter6_f_543_17558)">
            <ellipse
              cx="142.548"
              cy="83.5085"
              rx="61.8388"
              ry="1.38513"
              transform="rotate(135 142.548 83.5085)"
              fill="url(#paint6_radial_543_17558)"
            />
          </g>
          <g opacity="0.8" filter="url(#filter7_f_543_17558)">
            <ellipse
              cx="142.548"
              cy="83.5088"
              rx="61.8388"
              ry="1.38513"
              transform="rotate(-135 142.548 83.5088)"
              fill="url(#paint7_radial_543_17558)"
            />
          </g>
          <g filter="url(#filter8_f_543_17558)">
            <circle
              cx="176"
              cy="121"
              r="216"
              fill="url(#paint8_radial_543_17558)"
              fill-opacity="0.4"
            />
          </g>
          <path
            d="M-55.5 93C-55.5 86.6487 -50.3513 81.5 -44 81.5H12C18.3513 81.5 23.5 86.6487 23.5 93V149C23.5 155.351 18.3513 160.5 12 160.5H-44C-50.3513 160.5 -55.5 155.351 -55.5 149V93Z"
            fill="url(#paint9_radial_543_17558)"
          />
          <path
            d="M-55.5 93C-55.5 86.6487 -50.3513 81.5 -44 81.5H12C18.3513 81.5 23.5 86.6487 23.5 93V149C23.5 155.351 18.3513 160.5 12 160.5H-44C-50.3513 160.5 -55.5 155.351 -55.5 149V93Z"
            stroke="url(#paint10_linear_543_17558)"
          />
          <path
            d="M328.5 93C328.5 86.6487 333.649 81.5 340 81.5H396C402.351 81.5 407.5 86.6487 407.5 93V149C407.5 155.351 402.351 160.5 396 160.5H340C333.649 160.5 328.5 155.351 328.5 149V93Z"
            fill="url(#paint11_radial_543_17558)"
          />
          <path
            d="M328.5 93C328.5 86.6487 333.649 81.5 340 81.5H396C402.351 81.5 407.5 86.6487 407.5 93V149C407.5 155.351 402.351 160.5 396 160.5H340C333.649 160.5 328.5 155.351 328.5 149V93Z"
            stroke="url(#paint12_linear_543_17558)"
          />
          <path
            d="M328.5 189C328.5 182.649 333.649 177.5 340 177.5H396C402.351 177.5 407.5 182.649 407.5 189V245C407.5 251.351 402.351 256.5 396 256.5H340C333.649 256.5 328.5 251.351 328.5 245V189Z"
            fill="url(#paint13_radial_543_17558)"
          />
          <path
            d="M328.5 189C328.5 182.649 333.649 177.5 340 177.5H396C402.351 177.5 407.5 182.649 407.5 189V245C407.5 251.351 402.351 256.5 396 256.5H340C333.649 256.5 328.5 251.351 328.5 245V189Z"
            stroke="url(#paint14_linear_543_17558)"
          />
          <path
            d="M328.5 -3C328.5 -9.35128 333.649 -14.5 340 -14.5H396C402.351 -14.5 407.5 -9.35127 407.5 -3V53C407.5 59.3513 402.351 64.5 396 64.5H340C333.649 64.5 328.5 59.3513 328.5 53V-3Z"
            fill="url(#paint15_radial_543_17558)"
          />
          <path
            d="M328.5 -3C328.5 -9.35128 333.649 -14.5 340 -14.5H396C402.351 -14.5 407.5 -9.35127 407.5 -3V53C407.5 59.3513 402.351 64.5 396 64.5H340C333.649 64.5 328.5 59.3513 328.5 53V-3Z"
            stroke="url(#paint16_linear_543_17558)"
          />
          <path
            d="M232.5 189C232.5 182.649 237.649 177.5 244 177.5H300C306.351 177.5 311.5 182.649 311.5 189V245C311.5 251.351 306.351 256.5 300 256.5H244C237.649 256.5 232.5 251.351 232.5 245V189Z"
            fill="url(#paint17_radial_543_17558)"
          />
          <path
            d="M232.5 189C232.5 182.649 237.649 177.5 244 177.5H300C306.351 177.5 311.5 182.649 311.5 189V245C311.5 251.351 306.351 256.5 300 256.5H244C237.649 256.5 232.5 251.351 232.5 245V189Z"
            stroke="url(#paint18_linear_543_17558)"
          />
          <path
            d="M232.5 -3C232.5 -9.35128 237.649 -14.5 244 -14.5H300C306.351 -14.5 311.5 -9.35127 311.5 -3V53C311.5 59.3513 306.351 64.5 300 64.5H244C237.649 64.5 232.5 59.3513 232.5 53V-3Z"
            fill="url(#paint19_radial_543_17558)"
          />
          <path
            d="M232.5 -3C232.5 -9.35128 237.649 -14.5 244 -14.5H300C306.351 -14.5 311.5 -9.35127 311.5 -3V53C311.5 59.3513 306.351 64.5 300 64.5H244C237.649 64.5 232.5 59.3513 232.5 53V-3Z"
            stroke="url(#paint20_linear_543_17558)"
          />
          <path
            d="M136.5 189C136.5 182.649 141.649 177.5 148 177.5H204C210.351 177.5 215.5 182.649 215.5 189V245C215.5 251.351 210.351 256.5 204 256.5H148C141.649 256.5 136.5 251.351 136.5 245V189Z"
            fill="url(#paint21_radial_543_17558)"
          />
          <path
            d="M136.5 189C136.5 182.649 141.649 177.5 148 177.5H204C210.351 177.5 215.5 182.649 215.5 189V245C215.5 251.351 210.351 256.5 204 256.5H148C141.649 256.5 136.5 251.351 136.5 245V189Z"
            stroke="url(#paint22_linear_543_17558)"
          />
          <path
            d="M136.5 -3C136.5 -9.35128 141.649 -14.5 148 -14.5H204C210.351 -14.5 215.5 -9.35127 215.5 -3V53C215.5 59.3513 210.351 64.5 204 64.5H148C141.649 64.5 136.5 59.3513 136.5 53V-3Z"
            fill="url(#paint23_radial_543_17558)"
          />
          <path
            d="M136.5 -3C136.5 -9.35128 141.649 -14.5 148 -14.5H204C210.351 -14.5 215.5 -9.35127 215.5 -3V53C215.5 59.3513 210.351 64.5 204 64.5H148C141.649 64.5 136.5 59.3513 136.5 53V-3Z"
            stroke="url(#paint24_linear_543_17558)"
          />
          <path
            d="M40.5 189C40.5 182.649 45.6487 177.5 52 177.5H108C114.351 177.5 119.5 182.649 119.5 189V245C119.5 251.351 114.351 256.5 108 256.5H52C45.6487 256.5 40.5 251.351 40.5 245V189Z"
            fill="url(#paint25_radial_543_17558)"
          />
          <path
            d="M40.5 189C40.5 182.649 45.6487 177.5 52 177.5H108C114.351 177.5 119.5 182.649 119.5 189V245C119.5 251.351 114.351 256.5 108 256.5H52C45.6487 256.5 40.5 251.351 40.5 245V189Z"
            stroke="url(#paint26_linear_543_17558)"
          />
          <path
            d="M40.5 -3C40.5 -9.35128 45.6487 -14.5 52 -14.5H108C114.351 -14.5 119.5 -9.35127 119.5 -3V53C119.5 59.3513 114.351 64.5 108 64.5H52C45.6487 64.5 40.5 59.3513 40.5 53V-3Z"
            fill="url(#paint27_radial_543_17558)"
          />
          <path
            d="M40.5 -3C40.5 -9.35128 45.6487 -14.5 52 -14.5H108C114.351 -14.5 119.5 -9.35127 119.5 -3V53C119.5 59.3513 114.351 64.5 108 64.5H52C45.6487 64.5 40.5 59.3513 40.5 53V-3Z"
            stroke="url(#paint28_linear_543_17558)"
          />
          <path
            d="M-55.5 189C-55.5 182.649 -50.3513 177.5 -44 177.5H12C18.3513 177.5 23.5 182.649 23.5 189V245C23.5 251.351 18.3513 256.5 12 256.5H-44C-50.3513 256.5 -55.5 251.351 -55.5 245V189Z"
            fill="url(#paint29_radial_543_17558)"
          />
          <path
            d="M-55.5 189C-55.5 182.649 -50.3513 177.5 -44 177.5H12C18.3513 177.5 23.5 182.649 23.5 189V245C23.5 251.351 18.3513 256.5 12 256.5H-44C-50.3513 256.5 -55.5 251.351 -55.5 245V189Z"
            stroke="url(#paint30_linear_543_17558)"
          />
          <path
            d="M-55.5 -3C-55.5 -9.35128 -50.3513 -14.5 -44 -14.5H12C18.3513 -14.5 23.5 -9.35127 23.5 -3V53C23.5 59.3513 18.3513 64.5 12 64.5H-44C-50.3513 64.5 -55.5 59.3513 -55.5 53V-3Z"
            fill="url(#paint31_radial_543_17558)"
          />
          <path
            d="M-55.5 -3C-55.5 -9.35128 -50.3513 -14.5 -44 -14.5H12C18.3513 -14.5 23.5 -9.35127 23.5 -3V53C23.5 59.3513 18.3513 64.5 12 64.5H-44C-50.3513 64.5 -55.5 59.3513 -55.5 53V-3Z"
            stroke="url(#paint32_linear_543_17558)"
          />
          <circle
            cx="176"
            cy="121"
            r="232"
            fill="url(#paint33_radial_543_17558)"
          />
        </g>
        <g id="nuxt" filter="url(#filter9_bd_543_17558)">
          <path
            d="M232 93C232 86.3726 237.373 81 244 81H300C306.627 81 312 86.3726 312 93V149C312 155.627 306.627 161 300 161H244C237.373 161 232 155.627 232 149V93Z"
            fill="url(#paint35_radial_543_17558)"
            shape-rendering="crispEdges"
          />
          <path
            d="M232.5 93C232.5 86.6487 237.649 81.5 244 81.5H300C306.351 81.5 311.5 86.6487 311.5 93V149C311.5 155.351 306.351 160.5 300 160.5H244C237.649 160.5 232.5 155.351 232.5 149V93Z"
            stroke="url(#paint36_linear_543_17558)"
            stroke-linecap="round"
            shape-rendering="crispEdges"
          />
          <path
            d="M275.416 139H296.234C296.894 139 297.544 138.824 298.118 138.492C298.692 138.157 299.167 137.677 299.496 137.1C299.827 136.522 300.001 135.867 300.001 135.2C300.001 134.534 299.827 133.879 299.496 133.3L285.512 108.872C285.187 108.293 284.713 107.811 284.14 107.476C283.567 107.14 282.915 106.963 282.251 106.963C281.587 106.963 280.935 107.14 280.362 107.476C279.789 107.811 279.315 108.293 278.99 108.872L275.414 115.122L268.426 102.9C268.101 102.321 267.627 101.839 267.054 101.504C266.48 101.168 265.828 100.992 265.164 100.992C264.5 100.992 263.848 101.168 263.274 101.504C262.701 101.839 262.227 102.321 261.902 102.9L244.502 133.3C244.004 134.173 243.869 135.207 244.128 136.178C244.386 137.149 245.016 137.98 245.882 138.49C246.454 138.824 247.102 139 247.764 139H260.832C266.01 139 269.828 136.706 272.454 132.23L278.834 121.086L282.25 115.122L292.504 133.036H278.834L275.416 139ZM260.62 133.03L251.5 133.028L265.172 109.144L271.992 121.084L267.426 129.068C265.68 131.972 263.698 133.03 260.62 133.03Z"
            fill="#00DC82"
          />
        </g>
        <g id="vue" filter="url(#filter10_bd_543_17558)">
          <path
            d="M40 93C40 86.3726 45.3726 81 52 81H108C114.627 81 120 86.3726 120 93V149C120 155.627 114.627 161 108 161H52C45.3726 161 40 155.627 40 149V93Z"
            fill="url(#paint37_radial_543_17558)"
            shape-rendering="crispEdges"
          />
          <path
            d="M40.5 93C40.5 86.6487 45.6487 81.5 52 81.5H108C114.351 81.5 119.5 86.6487 119.5 93V149C119.5 155.351 114.351 160.5 108 160.5H52C45.6487 160.5 40.5 155.351 40.5 149V93Z"
            stroke="url(#paint38_linear_543_17558)"
            stroke-linecap="round"
            shape-rendering="crispEdges"
          />
          <path
            d="M95.225 99.1143H105.375L80 142.886L54.625 99.1143H74.0369L80 109.264L85.8362 99.1143H95.225Z"
            fill="#41B883"
          />
          <path
            d="M54.625 99.1143L80 142.886L105.375 99.1143H95.225L80 125.377L64.6481 99.1143H54.625Z"
            fill="#41B883"
          />
          <path
            d="M64.6481 99.1143L79.9999 125.504L95.2249 99.1143H85.8362L79.9999 109.264L74.0368 99.1143H64.6481Z"
            fill="#35495E"
          />
        </g>
        <g id="pdf" filter="url(#filter11_bd_543_17558)">
          <path
            d="M136 93C136 86.3726 141.373 81 148 81H204C210.627 81 216 86.3726 216 93V149C216 155.627 210.627 161 204 161H148C141.373 161 136 155.627 136 149V93Z"
            fill="url(#paint39_radial_543_17558)"
            shape-rendering="crispEdges"
          />
          <path
            d="M136.5 93C136.5 86.6487 141.649 81.5 148 81.5H204C210.351 81.5 215.5 86.6487 215.5 93V149C215.5 155.351 210.351 160.5 204 160.5H148C141.649 160.5 136.5 155.351 136.5 149V93Z"
            stroke="url(#paint40_linear_543_17558)"
            shape-rendering="crispEdges"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M177.736 129.563C177.547 130.012 177.575 130.498 177.816 130.932L182.837 139.981C183.752 141.63 186.825 141.524 187.546 139.818L202.751 103.806C203.403 102.262 201.464 100.818 199.49 101.377L189.447 104.221C188.722 104.426 188.159 104.877 187.92 105.444L177.736 129.563Z"
            fill="url(#paint41_linear_543_17558)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M161.095 129.742C160.909 130.189 160.938 130.673 161.178 131.104L166.104 139.981C167.019 141.63 170.092 141.524 170.813 139.818L186.018 103.806C186.67 102.262 184.731 100.818 182.757 101.377L172.746 104.212C172.018 104.418 171.453 104.872 171.215 105.442L161.095 129.742Z"
            fill="url(#paint42_linear_543_17558)"
          />
          <path
            d="M169.141 103.384C169.784 101.84 167.841 100.402 165.871 100.964L152.413 104.803C149.706 105.576 148.391 107.944 149.533 109.991L156.528 122.533C157.449 124.183 160.526 124.07 161.238 122.359L169.141 103.384Z"
            fill="url(#paint43_linear_543_17558)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_543_17558"
          x="71.3574"
          y="55.3892"
          width="137.466"
          height="50.1235"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4.5"
            result="effect1_foregroundBlur_543_17558"
          />
        </filter>
        <filter
          id="filter1_f_543_17558"
          x="72.1769"
          y="55.6084"
          width="137.466"
          height="50.1235"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4.5"
            result="effect1_foregroundBlur_543_17558"
          />
        </filter>
        <filter
          id="filter2_f_543_17558"
          x="117.866"
          y="13.3574"
          width="50.1235"
          height="137.466"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4.5"
            result="effect1_foregroundBlur_543_17558"
          />
        </filter>
        <filter
          id="filter3_f_543_17558"
          x="117.866"
          y="13.3574"
          width="50.1235"
          height="137.466"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4.5"
            result="effect1_foregroundBlur_543_17558"
          />
        </filter>
        <filter
          id="filter4_f_543_17558"
          x="117.486"
          y="14.7764"
          width="50.1235"
          height="137.465"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4.5"
            result="effect1_foregroundBlur_543_17558"
          />
        </filter>
        <filter
          id="filter5_f_543_17558"
          x="117.486"
          y="14.7764"
          width="50.1235"
          height="137.465"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4.5"
            result="effect1_foregroundBlur_543_17558"
          />
        </filter>
        <filter
          id="filter6_f_543_17558"
          x="89.8097"
          y="30.7705"
          width="105.476"
          height="105.476"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4.5"
            result="effect1_foregroundBlur_543_17558"
          />
        </filter>
        <filter
          id="filter7_f_543_17558"
          x="89.8097"
          y="30.771"
          width="105.476"
          height="105.476"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4.5"
            result="effect1_foregroundBlur_543_17558"
          />
        </filter>
        <filter
          id="filter8_f_543_17558"
          x="-120"
          y="-175"
          width="592"
          height="592"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="40"
            result="effect1_foregroundBlur_543_17558"
          />
        </filter>
        <filter
          id="filter9_bd_543_17558"
          x="184"
          y="33"
          width="176"
          height="176"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="24" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_543_17558"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_543_17558"
            result="effect2_dropShadow_543_17558"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_543_17558"
            result="shape"
          />
        </filter>
        <filter
          id="filter10_bd_543_17558"
          x="-8"
          y="33"
          width="176"
          height="176"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="24" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_543_17558"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_543_17558"
            result="effect2_dropShadow_543_17558"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_543_17558"
            result="shape"
          />
        </filter>
        <filter
          id="filter11_bd_543_17558"
          x="16"
          y="-39"
          width="320"
          height="320"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="60" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_543_17558"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="12" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.533293 0 0 0 0 0.49 0 0 0 0 0.816667 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_543_17558"
            result="effect2_dropShadow_543_17558"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_543_17558"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(140.09 80.451) rotate(90) scale(1.38513 61.8388)"
        >
          <stop stop-color="white" stop-opacity="0.572" />
          <stop offset="0.235" stop-color="#9788FF" stop-opacity="0.8" />
          <stop offset="1" stop-color="#312F3F" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(140.91 80.6701) rotate(90) scale(1.38513 61.8388)"
        >
          <stop stop-color="white" stop-opacity="0.572" />
          <stop offset="0.235" stop-color="#9788FF" stop-opacity="0.8" />
          <stop offset="1" stop-color="#312F3F" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(142.928 82.0902) rotate(90) scale(1.38513 61.8388)"
        >
          <stop stop-color="white" stop-opacity="0.572" />
          <stop offset="0.235" stop-color="#9788FF" stop-opacity="0.8" />
          <stop offset="1" stop-color="#312F3F" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(142.928 82.0902) rotate(90) scale(1.38513 61.8388)"
        >
          <stop stop-color="white" stop-opacity="0.572" />
          <stop offset="0.235" stop-color="#9788FF" stop-opacity="0.8" />
          <stop offset="1" stop-color="#312F3F" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(142.548 83.509) rotate(90) scale(1.38513 61.8388)"
        >
          <stop stop-color="white" stop-opacity="0.572" />
          <stop offset="0.235" stop-color="#9788FF" stop-opacity="0.8" />
          <stop offset="1" stop-color="#312F3F" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(142.548 83.509) rotate(90) scale(1.38513 61.8388)"
        >
          <stop stop-color="white" stop-opacity="0.572" />
          <stop offset="0.235" stop-color="#9788FF" stop-opacity="0.8" />
          <stop offset="1" stop-color="#312F3F" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(142.548 83.5085) rotate(90) scale(1.38513 61.8388)"
        >
          <stop stop-color="white" stop-opacity="0.572" />
          <stop offset="0.235" stop-color="#9788FF" stop-opacity="0.8" />
          <stop offset="1" stop-color="#312F3F" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(142.548 83.5087) rotate(90) scale(1.38513 61.8388)"
        >
          <stop stop-color="white" stop-opacity="0.572" />
          <stop offset="0.235" stop-color="#9788FF" stop-opacity="0.8" />
          <stop offset="1" stop-color="#312F3F" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(176 121) rotate(90) scale(216)"
        >
          <stop stop-color="#5A50A0" stop-opacity="0.8" />
          <stop offset="1" stop-color="#6359A0" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-56 81) rotate(45) scale(113.137)"
        >
          <stop stop-opacity="0.35" />
          <stop offset="1" stop-opacity="0.12" />
        </radialGradient>
        <linearGradient
          id="paint10_linear_543_17558"
          x1="24"
          y1="161"
          x2="-56"
          y2="81"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.15" />
          <stop offset="1" stop-color="white" stop-opacity="0.06" />
        </linearGradient>
        <radialGradient
          id="paint11_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(328 81) rotate(45) scale(113.137)"
        >
          <stop stop-opacity="0.35" />
          <stop offset="1" stop-opacity="0.12" />
        </radialGradient>
        <linearGradient
          id="paint12_linear_543_17558"
          x1="408"
          y1="161"
          x2="328"
          y2="81"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.15" />
          <stop offset="1" stop-color="white" stop-opacity="0.06" />
        </linearGradient>
        <radialGradient
          id="paint13_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(328 177) rotate(45) scale(113.137)"
        >
          <stop stop-opacity="0.35" />
          <stop offset="1" stop-opacity="0.12" />
        </radialGradient>
        <linearGradient
          id="paint14_linear_543_17558"
          x1="408"
          y1="257"
          x2="328"
          y2="177"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.15" />
          <stop offset="1" stop-color="white" stop-opacity="0.06" />
        </linearGradient>
        <radialGradient
          id="paint15_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(328 -15) rotate(45) scale(113.137)"
        >
          <stop stop-opacity="0.35" />
          <stop offset="1" stop-opacity="0.12" />
        </radialGradient>
        <linearGradient
          id="paint16_linear_543_17558"
          x1="408"
          y1="65"
          x2="328"
          y2="-15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.15" />
          <stop offset="1" stop-color="white" stop-opacity="0.06" />
        </linearGradient>
        <radialGradient
          id="paint17_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(232 177) rotate(45) scale(113.137)"
        >
          <stop stop-opacity="0.35" />
          <stop offset="1" stop-opacity="0.12" />
        </radialGradient>
        <linearGradient
          id="paint18_linear_543_17558"
          x1="312"
          y1="257"
          x2="232"
          y2="177"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.15" />
          <stop offset="1" stop-color="white" stop-opacity="0.06" />
        </linearGradient>
        <radialGradient
          id="paint19_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(232 -15) rotate(45) scale(113.137)"
        >
          <stop stop-opacity="0.35" />
          <stop offset="1" stop-opacity="0.12" />
        </radialGradient>
        <linearGradient
          id="paint20_linear_543_17558"
          x1="312"
          y1="65"
          x2="232"
          y2="-15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.15" />
          <stop offset="1" stop-color="white" stop-opacity="0.06" />
        </linearGradient>
        <radialGradient
          id="paint21_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(136 177) rotate(45) scale(113.137)"
        >
          <stop stop-opacity="0.35" />
          <stop offset="1" stop-opacity="0.12" />
        </radialGradient>
        <linearGradient
          id="paint22_linear_543_17558"
          x1="216"
          y1="257"
          x2="136"
          y2="177"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.15" />
          <stop offset="1" stop-color="white" stop-opacity="0.06" />
        </linearGradient>
        <radialGradient
          id="paint23_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(136 -15) rotate(45) scale(113.137)"
        >
          <stop stop-opacity="0.35" />
          <stop offset="1" stop-opacity="0.12" />
        </radialGradient>
        <linearGradient
          id="paint24_linear_543_17558"
          x1="216"
          y1="65"
          x2="136"
          y2="-15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.15" />
          <stop offset="1" stop-color="white" stop-opacity="0.06" />
        </linearGradient>
        <radialGradient
          id="paint25_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40 177) rotate(45) scale(113.137)"
        >
          <stop stop-opacity="0.35" />
          <stop offset="1" stop-opacity="0.12" />
        </radialGradient>
        <linearGradient
          id="paint26_linear_543_17558"
          x1="120"
          y1="257"
          x2="40"
          y2="177"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.15" />
          <stop offset="1" stop-color="white" stop-opacity="0.06" />
        </linearGradient>
        <radialGradient
          id="paint27_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40 -15) rotate(45) scale(113.137)"
        >
          <stop stop-opacity="0.35" />
          <stop offset="1" stop-opacity="0.12" />
        </radialGradient>
        <linearGradient
          id="paint28_linear_543_17558"
          x1="120"
          y1="65"
          x2="40"
          y2="-15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.15" />
          <stop offset="1" stop-color="white" stop-opacity="0.06" />
        </linearGradient>
        <radialGradient
          id="paint29_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-56 177) rotate(45) scale(113.137)"
        >
          <stop stop-opacity="0.35" />
          <stop offset="1" stop-opacity="0.12" />
        </radialGradient>
        <linearGradient
          id="paint30_linear_543_17558"
          x1="24"
          y1="257"
          x2="-56"
          y2="177"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.15" />
          <stop offset="1" stop-color="white" stop-opacity="0.06" />
        </linearGradient>
        <radialGradient
          id="paint31_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-56 -15) rotate(45) scale(113.137)"
        >
          <stop stop-opacity="0.35" />
          <stop offset="1" stop-opacity="0.12" />
        </radialGradient>
        <linearGradient
          id="paint32_linear_543_17558"
          x1="24"
          y1="65"
          x2="-56"
          y2="-15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.15" />
          <stop offset="1" stop-color="white" stop-opacity="0.06" />
        </linearGradient>
        <radialGradient
          id="paint33_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(176 121) rotate(90) scale(124.5 175.293)"
        >
          <stop stop-color="#373845" stop-opacity="0" />
          <stop offset="1" stop-color="#2A2E32" stop-opacity="0.9" />
        </radialGradient>
        <radialGradient
          id="paint35_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(232 81) rotate(45) scale(113.137)"
        >
          <stop stop-color="#3E4259" />
          <stop offset="1" stop-color="#020202" stop-opacity="0.08" />
        </radialGradient>
        <linearGradient
          id="paint36_linear_543_17558"
          x1="312"
          y1="161"
          x2="232"
          y2="81"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.1" />
          <stop offset="1" stop-color="white" stop-opacity="0.2" />
        </linearGradient>
        <radialGradient
          id="paint37_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40 81) rotate(45) scale(113.137)"
        >
          <stop stop-color="#3E4259" />
          <stop offset="1" stop-color="#020202" stop-opacity="0.08" />
        </radialGradient>
        <linearGradient
          id="paint38_linear_543_17558"
          x1="120"
          y1="161"
          x2="40"
          y2="81"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.1" />
          <stop offset="1" stop-color="white" stop-opacity="0.2" />
        </linearGradient>
        <radialGradient
          id="paint39_radial_543_17558"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(139 84) rotate(45) scale(106.773)"
        >
          <stop stop-color="#A28BFF" />
          <stop offset="0.516847" stop-color="#6757C8" />
          <stop offset="1" stop-color="#362988" />
        </radialGradient>
        <linearGradient
          id="paint40_linear_543_17558"
          x1="216"
          y1="161"
          x2="136"
          y2="81"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.05" />
          <stop offset="1" stop-color="white" stop-opacity="0.25" />
          <stop offset="1" stop-color="white" stop-opacity="0.25" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_543_17558"
          x1="182.256"
          y1="105.371"
          x2="213.43"
          y2="130.751"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_543_17558"
          x1="165.6"
          y1="105.371"
          x2="196.751"
          y2="130.635"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_543_17558"
          x1="152.823"
          y1="103.199"
          x2="170.926"
          y2="123.705"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0.4" />
        </linearGradient>
        <clipPath id="clip0_543_17558">
          <rect width="352" height="242" fill="white" />
        </clipPath>
        <clipPath id="clip1_543_17558">
          <rect width="352" height="242" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <svg
      width="352"
      height="242"
      class="absolute top-0 left-0 w-full h-full"
      viewBox="0 0 352 242"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="link-1"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M140 123 H120 V119 H136 V123 Z"
        fill="url(#link_1)"
      />
      <path
        id="link-2"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M233 123 H215 V119 H233 V123 Z"
        fill="url(#link_2)"
      />
      <defs>
        <linearGradient id="link_1" x1="0" x2="0">
          <stop offset="0%" stop-color="#9381FF" stop-opacity="0.1" />
          <stop offset="50%" stop-color="#9381FF" />
          <stop offset="100%" stop-color="#9381FF" stop-opacity="0.1" />
          <animate
            attributeName="x1"
            from="150%"
            to="-50%"
            dur="1s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="x2"
            from="250%"
            to="0%"
            dur="1s"
            repeatCount="indefinite"
          />
        </linearGradient>
        <linearGradient id="link_2" x1="0" x2="0">
          <stop offset="0%" stop-color="#9381FF" stop-opacity="0.1" />
          <stop offset="50%" stop-color="#9381FF" />
          <stop offset="100%" stop-color="#9381FF" stop-opacity="0.1" />
          <animate
            attributeName="x1"
            from="-50%"
            to="150%"
            dur="1s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="x2"
            from="0%"
            to="250%"
            dur="1s"
            repeatCount="indefinite"
          />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>
