import { title } from "process";

export const features = {
  "1": [
    {
      title: "theme",
      excerpt:
        "Personalize PDF viewer interface to match your website style whether with built-in dark/light mode or CSS variables making it visually engaging.",
      classes: "basis-full lg:basis-7/12",
    },
    {
      title: "responsive",
      excerpt:
        "Vue PDF Viewer is developed with web responsiveness in mind. It is compatible with popular screen sizes for a smooth viewing experience.",
      classes: "basis-full lg:basis-5/12",
    },
  ],
  "2": [
    {
      title: "accessibility",
      excerpt:
        "Easily navigate the documents through the PDF viewer using only keyboard. The component follows the Accessible Rich Internet Applications (ARIA) guideline.",
      classes: "basis-full lg:basis-5/12",
    },
    {
      title: "review",
      excerpt:
        "Allow your users to interact with your PDF document using a variety of annotating tools such as Highlight, Underline, Strikethrough, and more.",
      classes: "basis-full lg:basis-7/12",
    },
  ],
  "3": [
    {
      title: "localizations",
      excerpt:
        "Support translating UI with to 13 built-in locale files and right-to-left (RTL) layout, ensuring a flexible and better user experience across diverse users.",
      classes: "basis-full lg:basis-7/12 lg:w-7/12 w-full",
    },
    {
      title: "customizable panel",
      excerpt:
        "Create a custom panel by adding your own components to the toolbar, sidebar, or pages to match to provide smooth user experience for your users. ",
      classes: "basis-full lg:basis-5/12",
    },
  ],
};

export interface Locale {
  title: string;
  flagPath: string;
  alt: string;
}

export const locales: Locale[] = [
  {
    title: "España",
    flagPath: "/flags/spanish-language-icon.webp",
    alt: "Spanish language icon",
  },
  {
    title: "English (UK)",
    flagPath: "/flags/english-uk-language-icon.webp",
    alt: "English (UK) language icon",
  },
  {
    title: "English (US)",
    flagPath: "/flags/english-us-language-icon.webp",
    alt: "English (US) language icon",
  },
  {
    title: "Português",
    flagPath: "/flags/portuguese-language-icon.webp",
    alt: "Portuguese language icon",
  },
  {
    title: "中文",
    flagPath: "/flags/chinese-language-icon.webp",
    alt: "China language icon",
  },
  {
    title: "한국어",
    flagPath: "/flags/korean-language-icon.webp",
    alt: "Korean language icon",
  },
  {
    title: "日本語",
    flagPath: "/flags/japanese-language-icon.webp",
    alt: "Japanese language icon",
  },
  {
    title: "বাংলা",
    flagPath: "/flags/bengali-language-icon.webp",
    alt: "Bengali language icon",
  },
  {
    title: "Hindi",
    flagPath: "/flags/hindi-language-icon.webp",
    alt: "Hindi language icon",
  },
  {
    title: "Deutsch",
    flagPath: "/flags/german-language-icon.webp",
    alt: "German language icon",
  },
  {
    title: "Français",
    flagPath: "/flags/french-language-icon.webp",
    alt: "French language icon",
  },
  {
    title: "Italiano",
    flagPath: "/flags/italian-language-icon.webp",
    alt: "Italy language icon",
  },
  {
    title: "Русский",
    flagPath: "/flags/russian-language-icon.webp",
    alt: "Russian language icon",
  },
  {
    title: "عربي",
    flagPath: "/flags/arabic-language-icon.webp",
    alt: "Arabic language icon",
  },
];

export const reasons = [
  {
    title: "Flexible",
    slug: "flexible",
    excerpt:
      "Available in TypeScript and JavaScript for scalability and compatibility with any Vue project",
  },
  {
    title: "Production Ready Component",
    slug: "productionReady",
    excerpt:
      "Support Vue 3 & Nuxt and is fully tested (spec.ts). You can quickly integrate with our starter kits",
  },
  {
    title: "Compatible with Browsers",
    slug: "browsersCompatible",
    excerpt:
      "Function well across modern web browsers such as Chrome, Firefox, Microsoft Edge, Safari, and Opera",
  },
  {
    title: "Developer Friendly Doc",
    slug: "developerFriendly",
    excerpt:
      "Design for easy navigation with clear structures, examples and interactive demos",
  },
  {
    title: "Attractive and Customizable",
    slug: "customizable",
    excerpt:
      "Design with flexibility so you can extend the component easily to fit your needs",
  },
  {
    title: "Continuous Support",
    slug: "continuousSupport",
    excerpt:
      "Add features to Vue PDF Viewer when possible and make sure the component is up-to-date",
  },
];

export interface Price {
  license: string;
  formerPrize: string;
  currentPrize: string;
  totalDiscount: string;
  textButton: string;
  licenseFeatures: string[];
}
export const priceList: Price[] = [
  {
    license: "Developer License",
    formerPrize: "$59",
    currentPrize: "35",
    totalDiscount: "-40%",
    textButton: "Purchase",
    licenseFeatures: [
      "A single user",
      "Perpetual license",
      "Use in multiple websites",
      "Access to all features",
      "Free 1-year update and support",
    ],
  },
  {
    license: "Organization License",
    formerPrize: "$239",
    currentPrize: "143",
    totalDiscount: "-40%",
    textButton: "Purchase",
    licenseFeatures: [
      "Unlimited users",
      "Perpetual license",
      "Use in multiple websites",
      "Access to all features",
      "Free 1-year update and support",
      "Priority support",
    ],
  },
];
